.input-text {
    font-family: $secondary-font;
    font-weight: 400;
    height: 40px;
    line-height: 20px;
    padding: 9px 12px;
}

.btn {
    background-color: $primary-color;
    color: white;
    display: inline-block;
    padding: 12px 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: $secondary-font;
    cursor: pointer;
    transition: all .2s;
    border: none;

    &:hover {
        background-color: black;
    }

    &.btn-green {
        background-color: $green-color;
    }

    &.btn-gold {
        background-color: $secondary-color;
        color: $primary-color;

        &:hover {
            background-color:  $secondary-color-light;
        }
    }

    &.btn-padding {
        padding: 12px 20px;
    }

    &.btn-square {
        padding: 10px 10px 12px;
    }

    &[disabled] {
        background-color: $light-gray-color !important;
        color: $gray-color !important;
        cursor: auto;
    }
}


.payment-btn {
    border: 1px solid;
    width: 100%;
    padding: 25px 0;
    margin-bottom: 15px;
    cursor: pointer;
    transition: all .15s;

        &:hover {
            background-color: $primary-color;
            color: white;
        }
}