header {
    background-color: $primary-color;
    height: $header-height;
    position: absolute;
    top: $top-banner-height;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    z-index: 1000;
    @include breakpoint(small down) {
        padding: 0 20px;
        height: $header-height-mobile;
    }

      &.sticky {
        position: fixed;
        top: 0;
      }
}

.top-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: white;
  position: relative;
  z-index: 10000;
  @include breakpoint(medium down) {
    justify-content: center;
    padding: 0 15px;
  }

    span {
      width: 33.33%;
      text-align: center;
      padding: 5px 0;
      @include breakpoint(medium down) {
        width: 100%;
      }
      @include breakpoint(small down) {
        font-size: 12px;
        padding: 8px 0;
      }

        &:nth-child(1) {
          background-color: green;
        }
        &:nth-child(2) {
          color: $black;
        }
        &:nth-child(3) {
          background-color: red;
        }
    }
}

.main-menu {
    @include breakpoint(medium down) {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $primary-color;
        overflow: scroll;
        padding-top: 60px;
    }

    > ul {
        max-width: 85vw;
        margin: 0 auto;
    }

     .main-menu-item {
        display: inline-block;
        padding: 0 0.8vw;
        @include breakpoint(medium down) {
            display: block;
            border-bottom: 1px solid rgba(255, 255, 255, 0.28);
        }

        > a {
            line-height: $header-height - 1px;
            display: inline-block;
            position: relative;
            @include breakpoint(medium down) {
                line-height: 40px;
                display: block;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 4px;
                background-color: $secondary-color;
                transform: scaleY(0);
                transition: all .3s;
            }
        }
     }

     .main-menu-links {
        line-height: 1.8;
        width: 15%;
        @include breakpoint(medium down) {
            width: 100%;
        }

        a {
            transition: all .15s;
            &:hover {
                color: $secondary-color;
            }
        }

        &.menu-inline {
            width: 100%;
            max-width: 1170px;
            text-align: center;
            @include breakpoint(medium down) {
                text-align: left;
            }

            li { 
                display: inline-block;
                padding: 0 10px;
                @include breakpoint(medium down) {
                    display: block;
                    padding: 0;
                }
            }
        }
     }

     .mobile-menu-header {
        position: fixed;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        top: 0;
        left: 0;
        padding: 5px 8vw;
        background-color: $primary-color;
        z-index: 10;

            .ei-icon_close {
                font-size: 30px;
            }
     }

     .logout-button {
        background: none;
        border: none;
        color: white;
        display: block;
        padding: 11px 0;
        width: 100%;
        text-align: left;
    }

     .main-menu-dropdown {
        position: absolute;
        left: 0;
        right: 0;
        top: $header-height;
        // height: $dropdown-menu-height;
        background-color: $primary-color;
        transform: translateY(-500px);
        z-index: -1;
        opacity: 0;
        transition: all .3s;
        pointer-events: none;
        display: flex;
        padding: 26px;
        justify-content: center;
        font-size: 14px;
        @include breakpoint(medium down) {
            position: static;
            transform: translate(0);
            pointer-events: auto;
            opacity: 1;
            padding: 0;
            display: block;
            height: auto;
            display: none;
            margin-bottom: 25px;
            z-index: 100;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: $secondary-color;
            height: 1px;
            transition: all .3s;
            transition-delay: .3s;
            opacity: 0;
        }
    }

    @include breakpoint(large) {
        .main-menu-item:hover {
            > a::after {
                transform: scaleY(1);
            }

            .main-menu-dropdown {
                transform: translateY(0);
                opacity: 1;
                pointer-events: initial;
                &::after {
                    opacity: .2;
                }
            }
        }
    }
}


.menu-icons {
    width: 180px;
    display: flex;
    justify-content: space-between;
    @include breakpoint(medium down) {
        justify-content: flex-end;
        align-items: center;
    }
    @include breakpoint(small down) {
        width: auto;
    }

    .menu-icon {
        color: white;
        font-size: 20px;
        transition: all .15s ease-out;
        cursor: pointer;
        &:hover {
            color: $secondary-color;
        }
        &.ei-social_facebook_circle:hover {
            color: #547aef;
        }
    }
}


.logo {
    width: 180px;
    @include breakpoint(small down) {
        width: 130px;
    }
}

.profile-menu {

    &:hover .profile-menu-drop {
        opacity: 1;
        transform: translateY(0);
    }

    .profile-menu-drop {
        position: absolute;
        transition: all .2s;
        left: -58px;
        right: auto;
        top: 5px;
        margin: 0 auto;
        background-color: $primary-color;
        width: 140px;
        text-align: center;
        padding: 48px 0 9px;
        z-index: -1;
        opacity: 0;
        transform: translateY(-110px);

          &.drop-language {
            left: -24px;
            width: 68px;
          }
    }

    input {
        width: 92%;
        background-color: $primary-color;
        color: white;
        border: none;
        cursor: pointer;
        transition: all .15s;

        &:hover {
            color: $secondary-color;
        }
    }

    a:hover {
        color: $secondary-color;
    }
}