.product-wrapper {
    padding: 50px 0;
    @include breakpoint(medium down) { padding: 10px 0 50px; }

    .product-slider-container {
        display: flex;
        align-items: center;
        @include breakpoint(medium down) { flex-direction: column; }
        @include breakpoint(small down) { margin-bottom: 25px; }
    }

    #product-slider {
        width: 85%;
        border: 1px solid $border-color;
        @include breakpoint(medium down) { width: 100%; }
    }

    #product-slider-nav {
        width: 15%;
        padding-left: 20px;
        @include breakpoint(medium down) {
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
        }

        img {
            height: auto;
            border: 1px solid $border-color;
            margin: 5px 0;
            @include breakpoint(medium down) {
                margin: 6px 3px;
                width: 15%;
                height: 15%;
            }

            &.active { border-color: $primary-color; }
        }
    }

    .product-descr-container {
        padding-left: 30px;
        @include breakpoint(medium down) { padding-left: 0; }

        h1 {
            @include breakpoint(medium down) {
                font-size: 28px;
                line-height: 1.2;
                margin-bottom: 10px;
            }
            @include breakpoint(small down) { font-size: 25px; }
        }
    }

    .taxable-price {
        font-size: 22px;
        display: inline-block;

            .line-through {
                text-decoration: line-through;
                margin-left: 12px;
                color: $gray-color;
                font-weight: normal;
            }
    }

    .retail-price {
        display: inline-block;
        position: relative;
        padding-left: 19px;

        .price-label {
            position: absolute;
            top: -6px;
            text-transform: uppercase;
            font-size: 9px;
        }

        .line-through {
            font-size: 18px;
            text-decoration: line-through;
        }
    }

    #info-container {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        padding: 15px 0;
        margin: 15px 0;

        > div {
            font-size: 15px;
            color: $dark-gray-color;
        }
    }

    .add-to-cart-container {
        text-align: right;
        border-top: 1px solid $border-color;
        margin-top: 15px;
        padding-top: 20px;

        .add-to-cart-button {
            padding: 12px 25px;
            @include breakpoint(medium down) { width: 100%; }
        }
    }
}


.table-sizes {
    width: 100%;
    border-collapse: collapse;

    th {
        text-align: left;
        width: 33.33%;
        font-weight: 400;
        text-transform: capitalize;
        font-size: 14px;
    }

    .not-available {
        opacity: .3;
        height: 35px;
    }

    .i-number {
        text-align: center;
        border-radius: 25px;
        box-shadow: none;
        margin: 0;
    }

    .i-number-minus {
        position: absolute;
        top: 0px;
        left: 5px;
        padding: 6px;

        span { font-size: 20px; }
    }

    .i-number-plus {
        position: absolute;
        top: 0px;
        right: 7px;
        padding: 6px;

        span { font-size: 20px; }
    }
}


.limited-title {
    line-height: 23px;
    font-size: 30px;
    display: inline-block;
    padding: 0 15px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background-color: $secondary-color;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background-color: $secondary-color;
    }
}


.btn-loader {
    display: none;
    position: absolute;
    right: 3vw;
    bottom: 0;
}


.related-container { padding: 50px 0 40px; }