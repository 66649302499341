* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p, label { 
    font-family: $secondary-font;
    color: $dark-gray-color;
}

h1, h2, h3, h4, h5 {
    color: $primary-color;
}

body { overflow-x: hidden; }

a {
    text-decoration: none;
    color: inherit;
}

.wrapper {
    min-height: calc(100vh - #{$header-height + $footer-height + $top-banner-height});
    margin-top: $header-height;
    @include breakpoint(small down) {
        margin-top: $header-height-mobile;
    }
}

.bg-center {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.is-popup-open {
    overflow: hidden;
    position: fixed;
}

.txt-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.bg-color {
    background-color: $light-gray-color;
    min-height: calc(100vh - #{$header-height + $footer-height});
}

.relative { position: relative; }

.block { display: block !important; }

.width-100 { width: 100%; }

.list-unstyled { list-style: none; }


// Spacing
.margin-0 { margin: 0; }
.margin-bottom-10 { margin-bottom: 10px; }
.margin-bottom-20 { margin-bottom: 20px; }
.margin-bottom-30 { margin-bottom: 30px; }
.margin-top-50 { margin-top: 50px; }
.margin-top-30 { margin-top: 30px; }
.margin-top-20 { margin-top: 20px; }
.margin-top-10 { margin-top: 10px; }
.padding-left-5 { padding-left: 5px; }



// mixins
@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@mixin background-center {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}