#profile-container {
    padding: 50px 0 70px;
    @include breakpoint(small down) { padding: 15px 0 70px; }

    .form-horizontal {
        width: 100%;
    }
}

.profile-navigation {
    list-style: none;
    padding-right: 20px;
    @include breakpoint(small down) { padding: 0 0 30px; }

    a {
        border: 1px solid;
        width: 100%;
        display: inline-block;
        margin-top: -1px;
        padding: 15px;
        transition: all .1s;
        @include breakpoint(small down) { padding: 7px 15px; }

        &:hover {
            background-color: $primary-color;
            color: white;
            border-color: $primary-color;
        }
    }

    .active a {
        background-color: $primary-color;
        color: white;
        border-color: $primary-color; 
    }
}

.roles {
    list-style: none;
    margin-top: 7px;

    .role {
        display: inline-block;
        border: 1px solid;
        padding: 8px 15px;
        font-size: 15px;
        margin-bottom: 5px;
    }
}

.save-password {
    margin-top: 24px;
    @include breakpoint(medium down) { margin-top: 0; }
}


// Profile orders
.order-row {
    cursor: pointer;
    transition: all .15s;

    &:hover {
        background-color: #eae9e9;
    }
}

.responsive-table {
    overflow-x: auto;
}

.orders-table {
    width: 100%;
    border-collapse: collapse;
    font-family: $secondary-font;
    min-width: 650px;

    th {
        text-align: left;
        color: $secondary-color;
    }

    td {
        border-bottom: 1px solid #d4d2d2;
        padding: 10px 0;
    }
}

.order-controls {
    display: none;
}

.new-return-request {
    display: none;
}

.dl-horizontal {

    dt {
        width: 50%;
        float: left;
        clear: left;
    }

    dd {
        width: 50%;
        float: left;
        margin: 0;
        text-align: right;
    }
}

.order-controls {
    background-color: #eae9e9;

    > td {
        padding: 20px;
    }

    .order-controls-row {
        margin-bottom: 25px;
    }

    .text-wrap {
        margin-bottom: 30px;
    }

    hr {
        border: none;
        border-top: 1px solid #d4d2d2;
        margin-bottom: 20px;
    }
}

.row-returned-goods {
    padding: 20px 0;
}