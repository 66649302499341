.cart-vert-space {
    padding: 70px 0 140px;
    @include breakpoint(small down) {
        padding: 25px 0 80px;
    }
}

.cart-summary-list {

    li {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        font-family: $secondary-font;
    }
}


.cart-update {

    .button-cart-remove {
        position: absolute;
        left: -10px;
        top: -10px;
        font-size: 21px;
        height: 25px;
        width: 25px;
        background-color: $secondary-color;
        padding-bottom: 5px;
        border-radius: 15px;
        cursor: pointer;
    }

}


.cart-summary-detail {
    padding: 15px 0;
    font-family: $secondary-font;

        hr {
            margin: 5px 0 4px;
        }

        .cart-summary-detail-total {
            font-weight: bold;
            text-transform: uppercase;
        }
}