.checkout-title {
    border-bottom: 1px solid #bdbcbc;
    padding-top: 30px;
    margin-bottom: 20px;
}

#checkout-container {
    padding-bottom: 100px;
}

.checkout-response {
    padding: 70px 0 0;
}

.margin-mobile-50 {
    @include breakpoint(small down) { margin-bottom: 50px; }
}