// Colors
$primary-color: #1e2021;
$secondary-color: #d2a637;
$secondary-color-light: #efc45d;
$gray-color: #bfc0c3;
$dark-gray-color: rgba(85, 85, 85, 0.8);
$light-gray-color: #f5f5f5;
$green-color: #18ba6a;
$red-color: #d84e4e;
$border-color: #ececec;

// Fonts
$primary-font: 'Dosis', Arial, Helvetica, sans-serif;
$secondary-font: 'Lato', Arial, Helvetica, sans-serif;

$header-height: 68px;
$header-height-mobile: 50px;
$top-banner-height: 34px;
$footer-height: 673px;
$dropdown-menu-height: 250px;

// Slick slider
$slick-font-path: '/skins/italmarket2015/fonts/slick/';
$slick-loader-path: '/skins/italmarket2015/images/';

// Home
$category-box-height: 24vw;

// Off canvas
$offcanvas-sizes: (
    small: 300px,
    medium: 380px
);
$offcanvas-exit-background: rgba($primary-color, 0.4);
$offcanvas-background: $primary-color;
$offcanvas-shadow: 0 0 10px hsla(213, 100%, 6%, 0.3);
$offcanvas-overlap-zindex: 10;