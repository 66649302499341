.cms-page {
    padding: 30px 0 70px;
    @include breakpoint(small down) { padding: 30px 15px 70px; }

    h1 {
        @include breakpoint(small down) {
            margin: 0 0 10px;
            text-align: center;
        }
    }

    h3 { margin-top: 25px; }
}