@font-face {
    font-family: Elegant Icons;
    src: url(/skins/italmarket2015/fonts/icons/ElegantIcons.woff2) format('woff2'),
         url(/skins/italmarket2015/fonts/icons/ElegantIcons.woff) format('woff');
}

// Colors
.white { color: white; }
.gold { color: $secondary-color; }
.dark-gray { color: $dark-gray-color; }
.red { color: $red-color; }

// Transform
.upper { text-transform: uppercase; }
.txt-center { text-align: center; }
.txt-left { text-align: left; }

// Letter spacing
.sp-008 { letter-spacing: 0.08rem; }
.sp-03 { letter-spacing: 0.3rem; }

// Fonts
.dosis-medium {
    font-family: $primary-font;
    font-weight: 500;
}
.lato-regular {
    font-family: $secondary-font;
    font-weight: 400;
}
.lato-bold {
    font-family: $secondary-font;
    font-weight: 700;
}