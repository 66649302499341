.product {
    background-color: white;
    text-align: center;
    padding: 30px 25px;
    border: 1px solid $border-color;
    cursor: pointer;
    transition: all .15s;
    @include breakpoint(small down) { padding: 12px 10px; }

    &:hover {
        box-shadow: 0 0 29px #e2dede;
    }

        .product-img img {
            padding: 20px;
        }

        .title-price {
            color: $secondary-color;
            font-family: $secondary-font;
            font-weight: 700;
            font-size: 18px;
        }

        .line-through span {
            text-decoration: line-through;
            font-weight: normal;
            color: $gray-color;

            + span {
                margin-right: 5px;
            }
        }

        .product-code {
            h4 {
                font-size: 22px;
                @include breakpoint(small down) { font-size: 15px; }
            }
            span {
                font-family: $secondary-font;
                text-transform: capitalize;
                color: $primary-color;
                @include breakpoint(small down) { display: none; }
            }
        }
}