#offCanvasCart {
    padding: ($header-height + 34px) 0 0;
    @include breakpoint(small down) {
        padding: ($header-height-mobile + 34px) 0 0;
    }
}

#offCanvasCartContainer {
    height: 100%;
}

.off-cart-products {
    height: calc(100% - 178px);
    padding: 0 25px;
    overflow-y: scroll;
}

.off-cart-header {
    border-bottom: 2px solid $dark-gray-color;
    padding-bottom: 7px;
    @include flex-space-between;

        a {
            font-size: 23px;
            cursor: pointer;
        }
}

.off-cart-product {
    display: flex;
    margin: 20px 0;
}

.off-cart-img {
    width: 38%;

        .button-cart-remove {
            position: absolute;
            background-color: $secondary-color;
            width: 20px;
            height: 20px;
            @include flex-center;
            font-size: 20px;
            line-height: 10px;
            padding-bottom: 4px;
            border-radius: 10px;
            top: -8px;
            left: -8px;
            cursor: pointer;
        }

        img { border-radius: 3px; }
}

.off-cart-descr {
    width: 62%;
    padding-left: 15px;
}

.off-cart-title {
    display: flex;
    justify-content: space-between;
}

.off-cart-resume {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 25px;
    background-color: $primary-color;

        hr { border: 1px solid $dark-gray-color; }
}