.alert-box {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    text-transform: uppercase;
    z-index: 100;
    font-weight: 400;
    font-family: $primary-font;
    font-size: 20px;
    letter-spacing: 1px;
  }
  
  .alert {
    padding: 5px 0;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  
      &.alert-danger {
        background-color: $red-color;
        color: white;
      }
      &.alert-success {
        background-color: $green-color;
        color: white;
      }
  }
  
  
  
  @keyframes alert {
    0% {
      transform: translateY(100px);
    }
    100% {
      transform: translateY(0px);
    }
  }