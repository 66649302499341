.category-box {
    height: $category-box-height;
    transition: all .2s;
    @include breakpoint(small down) {
        height: $category-box-height + 12;
    }

    &.box-tall {
        height: $category-box-height * 2;
    }

    &:hover {
        opacity: .7;
    }
}

#slider-home {

    .slick-slide img { width: 100%; }
}

#slider-logos {
    padding: 80px 30px;
    overflow-x: hidden;
    @include breakpoint(small down) {
        padding: 40px 15px;
    }

    img {
        padding: 0 30px;
    }
}


.slider-home-arrow {
    width: 40px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    opacity: .7;
    cursor: pointer;
    @include breakpoint(small down) {
        width: 30px;
    }

        &.arrow-right { 
            right: 20px;
            @include breakpoint(small down) {
                right: 10px;
            }
         }

        &.arrow-left { 
            left: 20px;
            @include breakpoint(small down) {
                left: 10px;
            }
         }
        &:hover { opacity: 1 }
}