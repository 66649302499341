#reset-block {
    display: none;
}

.block-link {
    display: inline-block;
    cursor: pointer;
    transition: all .2s ease;

    &:hover {
        color: $secondary-color;
    }
}

#login-block {
    .background-red .white {
        color: $red-color;
    }
}