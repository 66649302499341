footer { height: $footer-height; }

.newsletter-wrapper {
    padding: 70px 0;
    background-image: url('/skins/italmarket2015/images/newsletter-bg.jpg');
    @include breakpoint(small down) { padding: 30px 0 25px; }

    &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.75);
    }

    .newsletter-container {
        max-width: 480px;
        margin: 0 auto;
        text-align: center;
        padding: 0 15px;
        z-index: 1;
        position: relative;
    }

    h3 { 
        font-size: 30px;
        @include breakpoint(small down) { font-size: 18px; }
    }

    .ei-icon_mail {
        font-size: 20px;
        position: absolute;
        top: 4px;
        right: 9px;
        color: $gray-color;
    }
}

.footer-title {
    font-size: 22px;
    padding-left: 10px;
    line-height: 15px;
    color: $primary-color;
    @include breakpoint(small down) {
        font-size: 18px;
        margin-bottom: 5px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 3px;
        height: 100%;
        left: 0;
        top: 0;
        background-color: $secondary-color;
    }
}

.footer-copyright {
    text-align: center;
    margin-top: 50px;
    @include breakpoint(small down) { 
        margin-top: 10px;
        text-align: left;
    }

    p {
        @include breakpoint(small down) {
            font-size: 14px;
            line-height: 1.6 !important;
            padding: 0 8px;
        }
    }
}

.footer-links {
    padding: 90px 0 50px;
    @include breakpoint(small down) { padding: 45px 15px 20px; }

    .columns {
        @include breakpoint(small down) { margin-bottom: 30px; }
    }

    p { line-height: 30px; }

    a {
        transition: all .2s ease;
        &:hover {
            color: $secondary-color;
        }
    }
}

#newsletter-step2, #success-message { display: none; }

#success-message {
    background-color: $green-color;
    padding: 8px 0;
}