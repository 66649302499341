.catalog-banner {
    @include flex-center;
    @include background-center;
    height: 18vw;
    @include breakpoint(small down) {
        height: 28vw;
    }

    &::after {
        content: '';
        background-color: rgba(0, 0, 0, 0.4);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

        > div {
            z-index: 1;
            padding-bottom: 2.5vw;
            @include breakpoint(small down) {
              display: none;
            }
        }
}

#filter-list {
    background-color: white;
    padding: 26px 28px;
    border: 1px solid $border-color;
    @include breakpoint(small down) {
      display: none;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1000;
      overflow: scroll;
      padding-top: 70px;
    }

      &.filter-open {
        display: block;
      }

    .filter-mobile-header {
      display: flex;
      justify-content: space-between;
      position: fixed;
      background-color: white;
      z-index: 1;
      top: 0;
      left: 0;
      right: 0;
      padding: 14px 29px;

        .ei-icon_close {
          font-size: 28px;
          line-height: 0px;
        }
    }

    .panel-title {
        font-size: 19px;
        position: relative;
        line-height: 14px;
        padding-left: 10px;
        margin-bottom: 10px;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            height: 100%;
            background-color: $secondary-color;
        }
    }

    .panel-body {
        margin-bottom: 30px;
    }

    .catalog-filter input {
        opacity: 0;
        width: 0;
        height: 0;
        display: none;
    }

    li {
        padding: 3px 0;
        transition: all .15s;

        &.active,
        &:hover {
          color: $secondary-color;
        }
    }
}

.pagination {
  margin: 30px 0 80px;

    li {
        display: inline-block;
        padding: 0 12px;
        position: relative;

        a {
            position: relative;
        }

        &:not(active) a:hover {
          color: $secondary-color;
        }

        &.active {
            color: white;
            &::before {
                position: absolute;
                content: "";
                width: 35px;
                height: 35px;
                background-color: $secondary-color;
                top: -5px;
                left: 0;
                right: 0;
                border-radius: 18px;
                margin: 0 auto;
            }
        }
    }
}


.current-filters {
    background-color: white;
    margin-bottom: 30px;
    padding: 10px 6px;
    border: 1px solid $border-color;
    @include breakpoint(small down) { 
      background: none;
      border: none;
      border-top: 1px solid #d8d5d5;
      padding: 7px 0;
      margin-bottom: 20px;
    }

    .remove-filter {
      border: 1px solid;
      padding: 8px 10px 8px 15px;
      display: inline-block;
      margin: 0 4px;
      transition: all .15s;
      @include breakpoint(small down) { 
        font-size: 14px;
        padding: 8px 4px 8px 8px;
        margin: 0 5px 5px 0;
      }

      &:hover {
        color: $secondary-color;
      }
    }
}


.order-options {
    background-color: white;
    border: 1px solid #ececec;
    margin-top: -25px;
    height: 50px;
    margin-bottom: 30px;
    position: relative;
    @include flex-space-between;
    padding: 0 20px;
    @include breakpoint(small down) { 
      margin-bottom: 15px; 
    }
}


.active-filters {
    display: flex;
    justify-content: space-between;
}


// Codice vecchio
@keyframes indeterminate-bar {
  0% {
    transform: translate(-50%) scaleX(0);
  }
  50% {
    transform: translate(0%) scaleX(0.3);
  }
  100% {
    transform: translate(50%) scaleX(0);
  }
}

#layout-options, #toggle-filters-button {
  margin-bottom: 5px;
}

#catalog-container {
  background-color: $light-gray-color;

  .catalog-spinner {
    display: none;
    z-index: 10000;
    position: fixed;
    top: $header-height;
    width: 100%;
    height: 3px;
    @include breakpoint(small down) { 
        top: 0;
    }
    
    &:after {
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation: indeterminate-bar 2s linear infinite;
        animation: indeterminate-bar 2s linear infinite;
        width:100%;
        height:2px;
        background-color: $secondary-color;
        content: '';
        display: block;
    }
  }

  &.catalog-loading {
    .catalog-spinner {
      display: block;
    }

    &:before {
      content: '';
      display: block;
      position: fixed;
      z-index: 10000;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(white, 0.5);
    }
  }
  
  .sort-options {
    position: relative;
    padding: 12px 0;
    
    ul {
       padding: 0;
       position: absolute;
       right: -20px;
       top: 38px;
       width: auto;
       display: none;
       z-index: 5;

       li {
         display: block;
       }
    }
      
    &:hover, &:focus, &.show-submenu {
      ul {
        display: block;
      }
    }
      
    .sort-dropdown {
      box-shadow: (0px 2px 3px rgba(0, 0, 0, 0.2));

      > li > a {
        text-align: left;
        padding: 5px 15px 5px 10px;
        font-weight: 400;
        display: block;
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid $border-color;
        text-transform: capitalize;
        margin-top: -1px;
        white-space: nowrap;

        &:hover {
          background-color: $light-gray-color;
        }
      }
    }
  }
}